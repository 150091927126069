import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Profile from "./routes/Profile";

const App = () => (
  <Router>
    <Route path="/t/:shareId" component={Profile} />
  </Router>
);

export default App;
