import email from "./channels/icon-email.png";
import envato from "./channels/icon-envato.png";
import evernote from "./channels/icon-evernote.png";
import facebook from "./channels/icon-facebook.png";
import feedly from "./channels/icon-feedly.png";
import flickr from "./channels/icon-flickr.png";
import github from "./channels/icon-github.png";
import google from "./channels/icon-google-plus.png";
import instagram from "./channels/icon-instagram.png";
import lastfm from "./channels/icon-lastfm.png";
import linkedin from "./channels/icon-linkedin.png";
import myspace from "./channels/icon-myspace.png";
import paypal from "./channels/icon-paypal.png";
import phone from "./channels/icon-phone.png";
import pinterest from "./channels/icon-pinterest.png";
import product from "./channels/icon-product-hunt.png";
import reddit from "./channels/icon-reddit.png";
import rss from "./channels/icon-rss.png";
import skype from "./channels/icon-skype.png";
import slack from "./channels/icon-slack.png";
import soundcloud from "./channels/icon-soundcloud.png";
import spotify from "./channels/icon-spotify.png";
import trello from "./channels/icon-trello.png";
import tumblr from "./channels/icon-tumblr.png";
import twitter from "./channels/icon-twitter.png";
import vimeo from "./channels/icon-vimeo.png";
import vine from "./channels/icon-vine.png";
import website from "./channels/icon-website.png";
import whatsapp from "./channels/icon-whatsapp.png";
import wordpress from "./channels/icon-wordpress.png";
import yelp from "./channels/icon-yelp.png";
import snapchat from "./channels/icon-snapchat.png";
import youtube from "./channels/icon-youtube.png";

const channels = {
  email,
  envato,
  evernote,
  facebook,
  feedly,
  flickr,
  github,
  google,
  instagram,
  lastfm,
  linkedin,
  myspace,
  paypal,
  phone,
  pinterest,
  product,
  reddit,
  rss,
  skype,
  slack,
  soundcloud,
  spotify,
  trello,
  tumblr,
  twitter,
  vimeo,
  vine,
  website,
  whatsapp,
  wordpress,
  yelp,
  youtube,
  snapchat,
};

const images = {
  channels,
};

export default images;
