import React, { Component } from "react";
import styled from "styled-components";
import { FUNCTIONS_BASE_URL } from "../config";

import images from "../images";
import { Logo } from "../images/Logo";

const LayoutContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 24px auto;
  min-height: calc(100vh - 48px);

  @media (max-width: 840px) {
    display: block;
  }
`;

const LogoContainer = styled.div`
  flex-grow: 1;
`;

const Card = styled.div`
  max-width: 450px;
  background: white;
  overflow: hidden;
  padding-bottom: 80px;
  margin: 0 auto;
  border-radius: 14px;
  box-shadow: 0 10px 15px #f13b08;
  margin-right: 24px;

  @media (max-width: 840px) {
    margin: 24px auto;
  }

  @media (max-width: 450px) {
    border-radius: 0;
  }
`;

const Container = styled.div`
  padding: 24px;
`;

const Photo = styled.div<{ photo: string }>`
  background: #f5f5f5;
  height: 0;
  padding-top: 100%;
  width: 100%;
  background-image: ${({ photo }) => `url(${photo})`};
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
`;

const Name = styled.div`
  font-size: 20px;
  color: #383838;
  font-weight: 900;
  flex-grow: 1;
`;

const Location = styled.div`
  font-size: 12px;
  color: #727272;
`;

const About = styled.div`
  font-size: 12px;
  line-height: 16px;
  white-space: pre-wrap;
`;

const Channels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 18px;
`;

const ContactIcon = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-right: 15px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const Information = styled.div`
  color: white;
  max-width: 450px;
  margin-left: 24px;
  margin-right: 24px;

  p {
    line-height: 1.5;
    font-size: 18px;
  }

  @media (max-width: 840px) {
    max-width: 840px;
    display: flex;
    align-items: center;
    p,
    h1 {
      display: none;
    }
  }

  @media (max-width: 690px) {
    display: block;
    text-align: center;
  }
`;

const Button = styled.div<{ primary?: boolean }>`
  background: ${(props) => (props.primary ? "white" : "#ff5b2b")};
  color: ${(props) => (props.primary ? "#ff5b2b" : "white")};
  border-color: ${(props) => (props.primary ? "#ff5b2b" : "white")};
  border: 2px solid white;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  margin-top: 18px;
  margin-right: 9px;
  text-decoration: none;
  transition: opacity 0.5s;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 840px) {
    margin-top: 0;
  }
  @media (max-width: 690px) {
    margin-top: 18px;
  }
`;

const DownloadButton = styled.div<{ primary?: boolean }>`
  background: ${({ primary }) => (primary ? "white" : "#ff5b2b")};
  color: ${({ primary }) => (primary ? "#ff5b2b" : "white")};
  border-color: ${({ primary }) => (primary ? "#ff5b2b" : "white")};
  border: 2px solid white;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  margin-top: 18px;
  margin-right: 9px;
  text-decoration: none;
  transition: opacity 0.5s;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 840px) {
    margin-top: 0;
  }
  @media (max-width: 690px) {
    margin-top: 18px;
  }
`;

const Channel = ({ channel }: any) => (
  <div onClick={channel.onPress}>
    <ContactIcon src={channel.icon} />
  </div>
);

const openURL = (url: string) => {
  window.location.href = url;
};

const getMobileOperatingSystem = () => {
  var userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;
  if (/android/i.test(userAgent)) return "android";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "ios";
  return "unknown";
};

class Profile extends Component<any> {
  state: any = {
    user: {},
  };

  componentWillMount() {
    const { shareId } = this.props.match.params;
    fetch(`${FUNCTIONS_BASE_URL}/getShare?shareId=${shareId}`)
      .then((response) => response.json())
      .then((user) => this.setState({ user }));

    const isWebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(
      navigator.userAgent
    );
    this.setState({ isWebview });
  }

  handleDownload = () => {
    const { shareId } = this.props.match.params;
    const { isWebview } = this.state;
    if (isWebview) {
      return alert(
        "Sorry, this feature only works in your native browser. Open this page in Chrome or Safari and try again."
      );
    }
    return window.open(
      `${FUNCTIONS_BASE_URL}/getContactCard?shareId=${shareId}`
    );
  };

  handleDownloadApp = () => {
    const userOs = getMobileOperatingSystem();
    if (userOs === "android")
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.commcards";
    else
      window.location.href =
        "https://itunes.apple.com/ca/app/commcards/id1337802700";
  };

  channels: any = {
    phone: {
      label: "Phone",
      icon: images.channels.phone,
      onPress: () => openURL(`tel:${this.state.user.channels.phone}`),
    },
    email: {
      label: "Email",
      icon: images.channels.email,
      onPress: () => openURL(`mailto:${this.state.user.channels.email}`),
    },
    website: {
      label: "Website",
      icon: images.channels.website,
      onPress: () => {
        const prepareUrl = (url: string) =>
          new RegExp("^https?://").test(url) ? url : `http://${url}`;
        return openURL(prepareUrl(this.state.user.channels.website));
      },
    },
    linkedin: {
      label: "LinkedIn",
      icon: images.channels.linkedin,
      onPress: () => openURL(this.state.user.channels.linkedin),
    },
    facebook: {
      label: "Facebook",
      icon: images.channels.facebook,
      onPress: () => openURL(this.state.user.channels.facebook),
    },
    instagram: {
      label: "Instagram",
      icon: images.channels.instagram,
      onPress: () =>
        openURL(
          `https://www.instagram.com/${this.state.user.channels.instagram}`
        ),
    },
    skype: {
      label: "Skype",
      icon: images.channels.skype,
      onPress: () =>
        alert(
          `We can't link directly to a Skype profile. You can add ${this.state.user.name} directly in Skype with the username "${this.state.user.channels.skype}"`
        ),
    },
    twitter: {
      label: "Twitter",
      icon: images.channels.twitter,
      onPress: () =>
        openURL(`https://www.twitter.com/${this.state.user.channels.twitter}`),
    },
    pinterest: {
      label: "Pinterest",
      icon: images.channels.pinterest,
      onPress: () =>
        openURL(
          `https://www.pinterest.com/${this.state.user.channels.pinterest}`
        ),
    },
    snapchat: {
      label: "SnapChat",
      icon: images.channels.snapchat,
      onPress: () =>
        openURL(
          `https://www.snapchat.com/add/${this.state.user.channels.snapchat}`
        ),
    },
  };

  render() {
    const { user } = this.state;
    return (
      <LayoutContainer>
        <Information>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <h1>The new way to network.</h1>
          <p>
            Grow your network with AI-driven suggestions, share your details
            with a simple link, and easily connect with the people at
            conferences you'll actually want to meet.
          </p>
          <Button primary onClick={this.handleDownloadApp}>
            Download App
          </Button>
          <DownloadButton onClick={this.handleDownload}>
            Add to Contacts
          </DownloadButton>
        </Information>
        <Card>
          <Photo photo={user.photo} />
          <Container>
            <Name>{user.name}</Name>
            <Location>{user.position}</Location>
            <Location>{user.location}</Location>
            <Channels>
              {user.channels &&
                Object.keys(user.channels).map((channel) => {
                  if (user.channels[channel] && this.channels[channel]) {
                    return (
                      <Channel key={channel} channel={this.channels[channel]} />
                    );
                  }
                })}
            </Channels>
            <About>{user.about}</About>
          </Container>
        </Card>
      </LayoutContainer>
    );
  }
}

export default Profile;
